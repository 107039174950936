import Vue from 'vue'
import router from '../router'

// Import the plugin here
import { Auth0Plugin } from '../auth'

// Import environment variables
const domain = process.env.VUE_APP_AUTHDOMAIN
const clientId = process.env.VUE_APP_AUTHCLIENT
const audience = process.env.VUE_APP_AUTHAUDIENCE

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})
