import { getInstance } from './index'

export const adminGuard = (to, from, next) => {
  const authService = getInstance()

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      const userRoles = []
      authService.user['https://userrolesfrog.admin/user_authorization'].roles.forEach(element => {
        userRoles.push(element)
      })
      if (userRoles.includes('Frog Admin')) {
        return next()
      } else {
        return next({ name: 'UserAuthError' })
      }
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}
