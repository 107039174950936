import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from './auth/authGuard'
import { adminGuard } from './auth/adminGuard'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      beforeEnter: authGuard,
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/FrogDashboard'),
          beforeEnter: authGuard
        },
        // Pages
        {
          name: 'Portals',
          path: 'portal-management',
          component: () => import('@/views/dashboard/PortalManagement'),
          beforeEnter: authGuard
        },
        {
          name: 'Portal Details',
          path: 'portal-management/:portalID/:portalName',
          props: true,
          component: () => import('@/views/dashboard/PortalDetails'),
          beforeEnter: adminGuard
        },
        {
          name: 'Exports',
          path: 'exports',
          component: () => import('@/views/dashboard/Exports'),
          beforeEnter: adminGuard
        },
        {
          name: 'Reports',
          path: 'reports',
          component: () => import('@/views/dashboard/Reports'),
          beforeEnter: authGuard
        },
        {
          name: 'Marketing Catalogue',
          path: 'marketing/catalogue',
          component: () => import('@/views/dashboard/Catalogue'),
          beforeEnter: authGuard
        },
        {
          name: 'Marketing Details',
          path: 'marketing/catalogue/:id/details',
          component: () => import('@/views/dashboard/MarketingDetails'),
          beforeEnter: authGuard
        },
        {
          name: 'Broadcast',
          path: 'marketing/broadcast',
          component: () => import('@/views/dashboard/Broadcast'),
          beforeEnter: adminGuard
        },
        {
          name: 'Tags',
          path: 'manage/tags',
          component: () => import('@/views/dashboard/tables/TagTable'),
          beforeEnter: adminGuard
        },
        {
          name: 'Groups',
          path: 'manage/groups',
          component: () => import('@/views/dashboard/tables/GroupTable'),
          beforeEnter: adminGuard
        },
        {
          name: 'Edit Group',
          path: 'manage/group/:id',
          component: () => import('@/views/dashboard/EditGroup'),
          beforeEnter: adminGuard
        },
        {
          name: 'Listings',
          path: 'manage/listings',
          component: () => import('@/views/dashboard/tables/ListingTable'),
          beforeEnter: adminGuard
        },
        {
          name: 'Edit Listing',
          path: 'manage/edit-listing/:id',
          component: () => import('@/views/dashboard/ListingWizard'),
          beforeEnter: adminGuard
        },
        {
          name: 'Videos',
          path: 'manage/videos',
          component: () => import('@/views/dashboard/tables/VideoTable'),
          beforeEnter: adminGuard
        },
        {
          name: 'Resources',
          path: 'manage/resources',
          component: () => import('@/views/dashboard/tables/ResourceTable'),
          beforeEnter: adminGuard
        },
        {
          name: 'Series',
          path: 'manage/series/:id',
          component: () => import('@/views/dashboard/ViewSeries'),
          beforeEnter: adminGuard
        },
        {
          name: 'Marketing',
          path: 'manage/marketing',
          component: () => import('@/views/dashboard/MarketingManagement'),
          beforeEnter: adminGuard
        }
      ]
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error')
        },
        {
          name: 'UserAuthError',
          path: '/unauthorised',
          component: () => import('@/views/pages/Unauthorised')
        }
      ]
    }
  ]
})
